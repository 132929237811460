import React from 'react';
import styled from "styled-components"

const BlockquoteContainer = styled.blockquote`
    font-style: italic;
    border: 0.1rem solid #3e545e;
    border-radius: 1rem;
    padding: 2.5rem;
`

const NewsUpdates = () => {
  return (
    <div>
      <h2><span className="font-secondary">Unleaving</span> is out NOW on <a href="https://www.xbox.com/en-ca/games/store/unleaving/9mz83x8td09r">Xbox!</a></h2>
      <p>Unleaving is available now on <a href="https://store.steampowered.com/app/1076720/">Steam</a> for PC & MacOs</p>
      <p><span className="font-secondary">Unleaving</span><span> is avaialble on </span><a href="https://store.epicgames.com/en-US/p/unleaving-9d452e"><span>Epic</span></a> Store!</p>
      <p>We're thrilled that orangutan matter and <a href="https://www.xbox.com/en-CA/games/id">ID @ Xbox</a> has partnered to bring <span className="font-secondary"><a href="https://www.xbox.com/en-ca/games/store/unleaving/9mz83x8td09r">Unleaving</a></span> to Xbox</p>
      <BlockquoteContainer>"The power of the art and the music to bring me to such emotion...in a video game? This game certainly hit me in ways that I don’t recall experiencing before" ~ <a href="https://thegamingoutsider.com/2024/03/27/unleaving-pc-review/mszymanski/">Marc, The Gaming Outsider</a></BlockquoteContainer>
      <BlockquoteContainer>"Great game. The artwork is awesome. I cried at the end. The music is incredible." <a href="https://steamcommunity.com/id/GAME_EMOTION/recommended/1076720/"> Game_Emotion</a></BlockquoteContainer>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/ykVY1xtq0rg?si=5KHDEYaLJFFGDki-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  );
};

export default NewsUpdates;